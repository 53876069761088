<template>
  <v-scale-transition>
    <kits-panel :kits-items="financeKits">
      <h3 class="headline grey--text text-xs-center hidden-sm-and-down">
        Finance
      </h3>
    </kits-panel>
  </v-scale-transition>
</template>

<script>
export default {
  components: {
    KitsPanel: () => import(/* webpackChunkName: "kitsPanel" */ '@/components/KitsPanel'),
  },
  data () {
    return {
      financeKits: [
        {
          icon: 'mdi-chart-box-outline',
          path: '/finance/statistics',
          title: 'Statistics',
          desc: 'View reports & statistics.',
        },
      ],
    }
  },
}
</script>
